<template>
  <div>
    <b-modal
      id="order-payment-wiretransfer-modal-1"
      ref="order-payment-wiretransfer-modal-1"
      size="lg"
      centered
      hide-header
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      body-class="pb-4 text-black"
    >
      <CLink @click="hide()">
        <CIcon name="cil-x" class="popup-close-btn" size="lg" />
      </CLink>
      <div class="modal-title-box">
        <div class="modal-title font-weight-bold">{{ $t("payment.wireTransfer.popupTitle") }}</div>
        <div class="modal-subtitle">
          {{ $t("payment.wireTransfer.popupDescription") }}
        </div>
      </div>

      <div class="payment-info-box">
        <span>{{ $t("payment.wireTransfer.orderNumber") }} : {{ wireTransferInfo.moid }}</span>
        <span>{{ $t("payment.wireTransfer.orderDate") }} : {{ formatDateTime(wireTransferInfo.orderDate) }}</span>
      </div>

      <div class="artwork-details ">
        <div class="d-flex">
          <div class="left">
            <div class="font-weight-bold small-space">{{ $t("payment.wireTransfer.artworkName") }}</div>
            <div class="space">{{ activeLanguage === 'ko' ? orderPaymentInfo.productName : orderPaymentInfo.productNameEn }}</div>
            <div class="artwork-card">
              <img :src="replaceDisplayDetailUrl(paymentProductInfo)" class="artwork-thumbnail" />
            </div>
          </div>
          <div>
            <div class="font-weight-bold small-space">{{ $t("payment.wireTransfer.paymentInfo") }}</div>
            <div class="artwork-card">
              <div class="d-flex flex-column">
                <span class="space">{{ $t("payment.wireTransfer.depositInfo") }}</span>
                <span class="small text-muted" v-html="
                  $t('payment.wireTransfer.depositDescription1', {
                    year: getExpYear(),
                    month: getExpMonth(),
                    day: getExpDay(),
                    time: getExpTime()
                  })
                "></span>
                <span class="small text-muted">{{ $t("payment.wireTransfer.depositDescription2") }}</span>
                <span class="small space text-muted">{{ $t("payment.wireTransfer.depositDescription3") }}</span>
                <span class="small">{{ $t("payment.wireTransfer.depositDescription4") }}</span>
                <span class="small text-muted">{{ $t("payment.wireTransfer.depositDescription5") }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="payment-details d-flex">
          <div>
            <div class="title left">{{ $t("payment.wireTransfer.depositPrice") }}</div>
            <div class="value">{{ orderPaymentInfo.productPrice | currency }}원</div>
          </div>
          <div>
            <div class="title left">{{ $t("payment.wireTransfer.depositor") }}</div>
            <div class="value">{{ personalInfo.name }}</div>
          </div>
          <div>
            <div class="title">{{ $t("payment.wireTransfer.cashReceipt") }}</div>
            <div class="value">{{ $t("payment.wireTransfer.cashReceiptType", { number: personalInfo.mobile }) }}</div>
          </div>
        </div>
      </div>

      <div class="checkbox-wrapper">
        <input type="checkbox" id="checkbox-confirm" v-model="checkboxConfirm" />
        <label for="checkbox-confirm">{{ $t("payment.wireTransfer.checkboxConfirm") }}</label>
      </div>
      
      <div class="button-wrapper">
        <Button
          :label="$t('payment.wireTransfer.paymentConfirm')"
          class="w-100"
          :onClick="next"
          :disabled="!checkboxConfirm"
        />
      </div>
    </b-modal>

    <PaymentWireTransfer2 
      :order-payment-info="orderPaymentInfo" 
      :payment-product-info="paymentProductInfo"
      :member-info="memberInfo"
      :personal-info="personalInfo"
      :wire-transfer-info="wireTransferInfo"
      ref="paymentWireTransfer2" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import PaymentWireTransfer2 from "@/views/threespace/payment/PaymentWireTransfer2";
import Button from "@/views/threespace/components/common/Button.vue";

const paymentHelper = createNamespacedHelpers("payment");

export default {
  name: "PaymentWireTransfer1",
  components: {
    PaymentWireTransfer2,
    Button,
  },
  props: {
    paymentProductInfo: {
      type: Object,
      default: () => {},
    },
    orderPaymentInfo: {
      type: Object,
      default: () => {},
    },
    memberInfo: {
      type: Object,
      default: () => {},
    },
    personalInfo: {
      type: Object,
      default: () => {},
    },
    wireTransferInfo: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      form: this.getInitForm(),
      checkboxConfirm: false,
    };
  },
  computed: {
    activeLanguage() {
      return this.getActiveLanguage();
    },
  },
  mounted() {},
  methods: {
    ...paymentHelper.mapActions([
      "addPaymentWireTransfer"
    ]),
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    },
    onChangeTotalTerms() {
      this.form.paymentTerms = this.form.totalTerms;
      this.form.privacyPolicyTerms = this.form.totalTerms;
      this.form.privacyThirdPartyTerms = this.form.totalTerms;
    },
    next() {
      this.addPaymentWireTransfer(this.wireTransferInfo)
      .then((data) => {
        this.$refs["paymentWireTransfer2"].show();
        this.$refs["order-payment-wiretransfer-modal-1"].hide();
      })
      .catch(err => {
        this.$log.error(err);
      });
    },
    show() {
      this.form = this.getInitForm();
      this.$refs["order-payment-wiretransfer-modal-1"].show();
    },
    hide() {
      if (confirm(this.$t("payment.payCancelMsg"))) {
        this.$refs["order-payment-wiretransfer-modal-1"].hide();
      }
    },
    getInitForm() {
      return {
        totalTerms: false,
        paymentTerms: false,
        privacyPolicyTerms: false,
        privacyThirdPartyTerms: false,
      };
    },
    replaceDisplayDetailUrl(item) {
      if (
        item.displayPosterUrl !== undefined &&
        item.displayPosterUrl !== null &&
        item.displayPosterUrl !== "" &&
        item.displayPoster === "THREESPACE"
      ) {
        return item.displayPosterUrlThreespace; // 3space S3 URL
      } else {
        if (
          item.displayPosterUrl !== undefined &&
          item.displayPosterUrl.startsWith("https://ipfs.io/")
        ) {
          return item.displayPosterUrl.replace(
            "https://ipfs.io/",
            "https://ipfs.3space.art/"
          );
        }
        return item.displayPosterUrl;
      }
    },
    formatDateTime(dateString) {
      if (!dateString) return '';
      const d = new Date(dateString);
      return d.getFullYear() + '-' + 
             String(d.getMonth() + 1).padStart(2, '0') + '-' +
             String(d.getDate()).padStart(2, '0') + ' ' +
             String(d.getHours()).padStart(2, '0') + ':' +
             String(d.getMinutes()).padStart(2, '0') + ':' +
             String(d.getSeconds()).padStart(2, '0');
    },
    getExpYear() {
      return this.wireTransferInfo.vbankExpDate ? this.wireTransferInfo.vbankExpDate.substring(0, 4) : '' ;
    },
    getExpMonth() {
      return this.wireTransferInfo.vbankExpDate ? this.wireTransferInfo.vbankExpDate.substring(4, 6) : '';
    },
    getExpDay() {
      return this.wireTransferInfo.vbankExpDate ? this.wireTransferInfo.vbankExpDate.substring(6, 8) : '';
    },
    getExpTime() {
      return this.wireTransferInfo.vbankExpTime ? `${this.wireTransferInfo.vbankExpTime.substring(0, 2)}:${this.wireTransferInfo.vbankExpTime.substring(2, 4)}` : '';
    },
  },
  filters: {
    currency: (value) => {
      if (!value) return "";
      return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
  },
};
</script>